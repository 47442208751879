.homeMobileContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  background-color: #ffffff;
}

.textContent {
  padding: 20px;
  text-align: center;
}

.homeMobileTitle {
  font-size: 32px;
  margin-bottom: 8px;
  letter-spacing: -1.5px;
}
.biggerText {
  font-size: 42px;
}

.homeMobileSubtitle {
  font-size: 20px;
  color: #33245c;
  line-height: 1.3;
  padding-inline: 12px;
  margin-bottom: 10px;
}

.socialIcons {
  display: flex;
  justify-content: center;
  gap: 13px;
  margin-bottom: 0px;
}

.socialIcon {
  width: 48px;
  aspect-ratio: 1;
  transition: transform 0.3s ease;
}

.socialIcon:hover {
  transform: scale(1.1);
}

.imageContainer {
  width: 100%;
  height: auto;
}

.mainImage {
  width: 100%;
  height: auto;
  display: block;
}
