body,
html {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.AppMobile {
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #f8b208;
}

.AppMobileParentContainer {
  position: absolute;
  width: 430px;
  height: 932px;
  transform-origin: top left;
}

.initialMessageMobile {
  position: absolute;
  top: 730px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  justify-content: center;
}

.initialMessageMobile h4 {
  font-size: 1.3rem;
}

#ArrowMobile {
  position: absolute;
  left: 49px;
  top: -67px;
  transform: scaleY(-1) scale();
  rotate: -12deg;
  width: 32px;
  opacity: 0.7;
}

.SecondMessageMobile {
  position: absolute;
  pointer-events: none;
  top: 670px;
  left: 120px;
  z-index: 100;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  animation: fadeInOut 5s ease-in-out;
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  20%,
  80% {
    opacity: 1;
  }
}

#Navigate {
  font-size: 1.2rem;
}

#SecondArrowMobile {
  position: absolute;
  left: 150px;
  top: 60px;
  rotate: 125deg;
  width: 30px;
}

#mainTvMobile {
  position: absolute;
  width: 260px;
  top: 45%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: all 1.2s linear;
}

#switchKeyMobile {
  position: absolute;
  width: 33px;
  top: 646px;
  left: 112px;
  rotate: -88deg;
  transition: all 1.2s linear;
  cursor: pointer;
  z-index: 100;
}

#vhfToggleMobile {
  position: absolute;
  width: 25px;
  top: 646px;
  left: 160px;
  transition: all 1.2s linear;
}

.pageSelectorMobile {
  background-color: #cf773a;

  width: 14px;
  aspect-ratio: 1;
  border-radius: 100%;
  position: absolute;
  top: 650px;
  opacity: 0.4;
  transition: all 1.2s linear;
}

#pageSelectorMobile1 {
  left: 234px;
}
#pageSelectorMobile2 {
  left: 259px;
}
#pageSelectorMobile3 {
  left: 284px;
}
#pageSelectorMobile4 {
  left: 310px;
}

.childContainerMobile {
  opacity: 0;
  width: 230px;
  height: 469px;
  position: absolute;
  top: 203px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0.6rem;
  background-color: #fff;
  overflow: hidden;
  transition: all 1.2s linear, opacity 0.5s linear;
}

/* TvMobile On Mode */
.AppMobileParentContainer.tv-on #mainTvMobile {
  width: 420px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.AppMobileParentContainer.tv-on #switchKeyMobile {
  width: 56px;
  top: 830px;
  left: 47px;
  rotate: 6deg;
}

.AppMobileParentContainer.tv-on #vhfToggleMobile {
  width: 45px;
  top: 828px;
  left: 120px;
}

.AppMobileParentContainer.tv-on .pageSelectorMobile {
  opacity: 0.7;
  width: 28px;
  top: 835px;
}

.AppMobileParentContainer.tv-on #pageSelectorMobile1 {
  left: 243.5px;
}
.AppMobileParentContainer.tv-on #pageSelectorMobile2 {
  left: 285px;
}
.AppMobileParentContainer.tv-on #pageSelectorMobile3 {
  left: 325.5px;
}
.AppMobileParentContainer.tv-on #pageSelectorMobile4 {
  left: 366.5px;
}

.AppMobileParentContainer.tv-on .childContainerMobile {
  opacity: 1;
  width: 374px;
  height: 760px;
  top: 40px;
  border-radius: 1rem;
  transition: all 1.2s linear, opacity 0.5s linear 1.7s;
}

/* White Noise */
#whiteNoiseMobile {
  width: 230px;
  height: 469px;
  pointer-events: none;
  position: absolute;
  top: 203px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 11;
  border-radius: 0.6rem;
  overflow: hidden;
  opacity: 0;
  transition: all 1.2s linear, opacity 0.5s linear;
}

.AppMobileParentContainer.tv-on #whiteNoiseMobile {
  width: 374px;
  height: 760px;
  top: 40px;
  border-radius: 1rem;
  transition: all 1.2s linear, opacity 0.5s linear 1.7s;
}

.childContainerMobile:not(.visible) {
  transition: all 1.2s linear, opacity 0.3s linear; /* Faster opacity change when turning off */
}

#whiteNoiseMobile.visible {
  opacity: 1;
}

.AppMobileParentContainer.tv-on .childContainerMobile.visible,
.AppMobileParentContainer.tv-on #whiteNoiseMobile.visible {
  opacity: 1;
}

#whiteNoiseContentMobile {
  background-image: url(../src/Pages/Mobile/Assets/whiteNoise.webp);
  background-size: contain;
  position: absolute;
  inset: -10%;
  animation: shiftMobile 0.2s linear infinite both;
  pointer-events: none;
}

@keyframes shiftMobile {
  0% {
    transform: translateX(10%) translateY(10%);
  }
  100% {
    transform: translateX(-10%) translateY(-10%);
  }
}

/* Button Face Styles */
.pageSelectorMobile .buttonFaceMobile {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-color: #d78a54;
  transition: all 0.1s ease;
  box-shadow: inset -3px -3px 8px rgba(255, 255, 255, 0.5),
    inset 3px 3px 8px rgba(0, 0, 0, 0.2), 3px 3px 15px rgba(0, 0, 0, 0.6);
}

.pageSelectorMobile:active .buttonFaceMobile {
  box-shadow: inset 3px 3px 8px rgba(0, 0, 0, 0.5),
    inset -1px -1px 5px rgba(255, 255, 255, 0.2);
  transform: translateY(2px);
}

.pageSelectorMobile.active .buttonFaceMobile {
  background-color: #ffb84dd6;
}
