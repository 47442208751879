.loadingScreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f8b208;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loadingDots {
  position: relative;
  width: 80px;
  height: 80px;
  animation: rotate 5s linear infinite;
}

.dot {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #3a3a39;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  animation: pulse 1.5s ease-in-out infinite;
}

.dot:nth-child(2) {
  top: 25%;
  left: 100%;
  animation-delay: 0.3s;
}

.dot:nth-child(3) {
  top: 75%;
  left: 100%;
  animation-delay: 0.6s;
}

.dot:nth-child(4) {
  top: 100%;
  left: 50%;
  animation-delay: 0.9s;
}

.dot:nth-child(5) {
  top: 75%;
  left: 0;
  animation-delay: 1.2s;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(0.5) translateX(-100%);
  }
  50% {
    transform: scale(1.5) translateX(-33%);
  }
}
