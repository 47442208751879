.aboutMobileContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  overflow-y: auto;
  padding: 20px;
}

.aboutMobileTitle {
  font-size: 42px;
  text-align: center;
  margin-bottom: 20px;
  letter-spacing: -1.5px;
}

.videoContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  border-radius: 16px;
}

.aboutVideo {
  width: 100%;
  border-radius: 1rem;
  height: auto;
  object-fit: cover;
}

.bioContainer {
  margin-bottom: 30px;
}

.bioContainer p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 15px;
  text-align: justify;
}

.infoContainer {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.experiences,
.education {
  margin-bottom: 20px;
}

.experiences h2,
.education h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.experiences ul,
.education ul {
  padding-left: 20px;
}

.experiences li,
.education li {
  font-size: 16px;
  margin-bottom: 8px;
}

.socialIcons {
  display: flex;
  justify-content: center;
  gap: 13px;
  margin-top: auto;
  padding-top: 20px;
  padding-bottom: 40px;
}

.socialIcon {
  width: 48px;
  aspect-ratio: 1;
  transition: transform 0.3s ease;
}
.infoContainer h2 {
  text-align: center;
  font-size: 30px;
}
@media (max-width: 380px) {
  .aboutMobileTitle {
    font-size: 32px;
  }

  .bioContainer p,
  .experiences li,
  .education li {
    font-size: 14px;
  }

  .experiences h2,
  .education h2 {
    font-size: 22px;
  }
}
