.WorkContainer {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  overflow-y: scroll;
  text-align: center;
  padding-left: 8px;
}

.WorkHeader {
  width: 100%;
  height: 215px;
  position: relative;
}

.Logo {
  width: 123px;
  height: auto;
  position: absolute;
  top: 0;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  will-change: transform; /* Optimizes animation */
}

#Figma {
  left: 810px;
  top: -8px !important;
  animation: floatFigma 4s infinite ease-in-out;
}

@keyframes floatFigma {
  0% {
    transform: translateY(0); /* Original position */
  }
  50% {
    transform: translateY(-20px); /* Moves up by 20px */
  }
  100% {
    transform: translateY(0); /* Back to original */
  }
}

#VsCode {
  left: 70px;
  animation: floatVsCode 5s infinite ease-in-out;
}

@keyframes floatVsCode {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-25px);
  }
  100% {
    transform: translateY(0);
  }
}

#Xd {
  left: 875px;
  animation: floatXd 5s infinite ease-in-out;
}

@keyframes floatXd {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-25px);
  }
  100% {
    transform: translateY(0);
  }
}

.interfaceDesignContainer {
  margin-top: 30px;
  width: 100%;
  height: 825px;
}

.interfaceDesignContainer h2 {
  padding-block: 12px;
}

.interfaceDesignContainer h3 {
  padding-inline: 188px;
}
