.contactContainer {
  width: 100%;
  height: 100%;
  background-color: #fff;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.contentSection h3 {
  width: 50%;
  margin-inline: auto;
}

.socialIcons {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 20px;
}

.iconLink {
  text-decoration: none;
  transition: transform 0.3s ease;
  z-index: 100;
}

.iconLink:hover {
  transform: translateY(-5px);
}

.icon {
  width: 56px;
  object-fit: cover;
}

.bgImage {
  position: absolute;
  bottom: -25px;
  left: 0;
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 38px 38px 0 0;
  z-index: 1;
  margin-top: 8px;
  opacity: 0.98;
}
