.workSampleWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  gap: 32px;
}

.workSampleContainer {
  width: 100%;
  height: 596px;
  display: grid;
  grid-template-columns: 555px 1fr;
  grid-template-areas: "left right";
  margin-block: 14px;
}

.workSampleMedia {
  width: 100%;
  grid-area: left;
  padding-left: 18px;
  padding-right: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.workSampleMedia img {
  width: 100%;
  height: auto;
  margin-left: 8px;
}

.workSampleInfo {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-area: right;
  padding-right: 44px;
  padding-left: 20px;
}

.workSampleInfo h2 {
  margin-bottom: 10px;
}

.workSampleInfo p {
  margin-bottom: 20px;
  text-align: justify;
  padding-top: 10px;
  padding-bottom: 14px;
  padding-inline: 12px;
}

.buttonPrimary {
  display: inline-block;
  width: 280px;
  padding-block: 8px;
  margin-inline: auto;
  margin-bottom: 10px;
  background-color: #595992;
  color: #ffffff;
  text-decoration: none;
  border-radius: 16px;
  text-align: center;
  position: relative;
  transition: all 0.3s ease;
  border: 1.5px solid transparent;
  box-shadow: inset 0 0 0 1px #595992;
}

.buttonPrimary::before {
  content: "";
  position: absolute;
  inset: 0;
  background: repeating-linear-gradient(
    0deg,
    transparent,
    rgba(255, 255, 255, 0.05) 1px,
    transparent 2px
  );
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 14px;
  pointer-events: none;
}

.buttonPrimary:hover {
  background-color: #4a4a7b;
  box-shadow: inset 0 0 0 1px #595992, 0 0 0 1px rgba(255, 255, 255, 0.5);
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.3);
}

.buttonPrimary:hover::before {
  opacity: 1;
}

.buttonSecondary {
  display: inline-block;
  width: 280px;
  padding-block: 8px;
  margin-inline: auto;
  background-color: #ffffff;
  color: #595992;
  text-decoration: none;
  border-radius: 16px;
  text-align: center;
  position: relative;
  transition: all 0.3s ease;
  border: 1.5px solid #595992;
}

.buttonSecondary::before {
  content: "";
  position: absolute;
  inset: 0;
  background: repeating-linear-gradient(
    0deg,
    transparent,
    rgba(89, 89, 146, 0.05) 1px,
    transparent 2px
  );
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 14px;
  pointer-events: none;
}

.buttonSecondary:hover {
  background-color: #f0f0f5;
  color: #4a4a7b;
  box-shadow: inset 0 0 0 1px #ffffff, 0 0 0 1px rgba(89, 89, 146, 0.5);
}

.buttonSecondary:hover::before {
  opacity: 1;
}
