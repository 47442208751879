/* --------- Root --------- */

:root {
  --clr-bg-fill: #f8b208;

  --clr-black: hsla(0, 0%, 0%, 1);
  --clr-black-300: hsla(0, 0%, 0%, 0.8);
  --clr-black-100: hsla(0, 0%, 0%, 0.3);

  --clr-white-400: hsla(0, 0%, 100%, 1);
  --clr-white-300: hsla(0, 0%, 100%, 0.9);
  --clr-white-200: hsla(0, 0%, 100%, 0.71);
  --clr-white-100: hsla(0, 0%, 100%, 0.14);

  --clr-btn-scnd: hsla(219, 75%, 19%, 0.86);
  --clr-gradient: linear-gradient(
    45deg,
    hsla(258, 29%, 68%, 1),
    hsla(0, 0%, 100%, 1)
  );
  --clr-star: hsla(41, 74%, 54%, 1);

  --h-font: "Roboto", sans-serif;
  --p-font: "Inter", sans-serif;

  --fw-thin: 250;
  --fw-regular: 400;
  --fw-semi-bold: 500;
  --fw-bold: 700;
}

/* @media (min-width: 50em) {
  :root {
  --fs-body: var(--fs-500);
  --fs-primary-heading: var(--fs-900);
  --fs-secondary-heading: var(--fs-800);
  
  --fs-nav: var(--fs-500);
  }
} */

/* --------- Reset --------- */

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  font: inherit;
}

ul[role="list"],
ol[role="list"] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

a {
  text-decoration: none;
  color: inherit;
  font-family: "PT Sans Narrow", sans-serif;
  font-size: 18px;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* --------- Global --------- */

body {
  background-color: var(--clr-bg-fill);

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

h1 {
  font-family: "Trochut", sans-serif;
  font-weight: var(--fw-bold);
  font-size: 68px;
  font-style: normal;

  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.25);
  line-height: normal;

  background: linear-gradient(90deg, #1c1eba 0%, #b95100 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  padding-top: 28px;
  padding-bottom: 12px;

  letter-spacing: -0.25856rem;
}

h2 {
  font-family: "Trochut", sans-serif;
  font-weight: var(--fw-bold);
  font-size: 38px;
  font-style: normal;

  background: linear-gradient(90deg, #1c1eba 0%, #b95100 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h3 {
  font-family: "PT Sans Narrow", sans-serif;
  font-weight: 500;
  font-size: 20px;
  font-style: normal;

  color: #33245c;
}

h4 {
  font-family: "Trochut", sans-serif;
  font-weight: var(--fw-bold);
  font-size: 26px;
  font-style: normal;
}

p,
li {
  font-family: "PT Sans Narrow", sans-serif;
  font-size: 20px;
}

/* test */

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #923f08;
}

::-webkit-scrollbar-thumb:hover {
  background: #7d3607;
}
